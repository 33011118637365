
.review-carousel
  .carousel__viewport, .carousel__track
    height: 100%
  .carousel__next
    right: 35px
  .carousel__prev
    left: 35px
  .carousel__prev, .carousel__next
    .carousel__icon
      color: #59595B
    z-index: 1
    position: absolute
    top: 50%
    width: 35px
    border-radius: 5px
    height: 70px
    background: #DEDEE0
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    &:hover
      background: darkgray
      transition: background-color 0.33s ease-in-out
.reviewsBg
  height: 400px
  @media screen and (max-width: 599px)
    height: 230px
  @media screen and (max-width: 1023px)
    background: linear-gradient(#F3F3F4, #9D9EA0)
  @media screen and (min-width: 1024px)
    background: linear-gradient(#F2F2F2, #BFBEC3)
.review-rating
  .q-rating__icon
    text-shadow: none !important
.rating-gradient
  background: rgb(16,108,235)
  background: -moz-linear-gradient(60deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  background: -webkit-linear-gradient(60deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  background: linear-gradient(60deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
