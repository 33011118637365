
.review-rating
  padding-bottom: 10px
.productReviewName
  padding-bottom: 10px
  @media screen and (min-width: 1023px)
    font-size: 18px
    line-height: 22px
  color: #4E4F53
  font-weight: 600
  width: 100%
  display: flex
  line-height: 1.2
  text-align: left
.reviewContainer
  background: white
  width: 850px
  background-size: 100% 3px
  border-radius: 6px
  color: #4F4F51
  text-align: center
  position: relative
  margin-right: 30px
  margin-left: 30px
  @media screen and (max-width: 600px)
    padding: 16px 10px
  @media screen and (min-width: 601px)
    padding: 30px 50px 30px 30px
  .productName
    text-align: left
    font-size: 17px
    font-weight: 600
    align-self: center
    width: 100%
  .name
    font-weight: 600
    font-size: 16px
    .subtitle
      font-weight: 400
  .content
    text-align: left
.imgStyle
  max-width: 150px
  margin-left: -30px
  @media screen and (max-width: 1024px)
    max-width: 130px !important
    margin-left: -15px
