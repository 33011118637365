

.socialBtn
  cursor: pointer
  width: 100%
  height: 50px
  border-radius: 10px
  display: flex
  align-items: center
  justify-content: space-between
  padding: 10px 15px 10px 15px
  @media screen and (max-width: 599px)
    max-width: 200px
    border-radius: 30px !important
  @media screen and (max-width: 1023px)
    border-radius: 5px
    padding: 10px 15px 10px 15px
  .label
    padding-left: 6px
    font-weight: 600
    font-size: 14px
    color: white
    text-transform: uppercase
    font-family: 'Work Sans', sans-serif
    @media screen and (max-width: 1023px)
      font-weight: 600
      font-size: 14px
.social
  background: url('~assets/img/homeSocialBg.webp') no-repeat
  background-size: cover
  background-position: center
  height: 290px
  border-radius: 10px
  @media screen and (max-width: 1023px)
    background: url('~assets/img/homeSocialBg-m.webp') no-repeat
    background-position: bottom center
    background-size: cover
    border-radius: 0
    height: 300px
    .q-btn
      background: #ffffff
      border: 0 !important
.mainLabel
  font-weight: 600
  color: white
  font-size: 24px
  margin-bottom: 24px
  width: 100%
  display: flex
  justify-content: center
  @media screen and (max-width: 599px)
    font-size: 20px !important
    margin-bottom: 20px
  @media screen and (min-width: 600px) and (max-width: 1023px)
    font-size: 22px
    margin-bottom: 20px
.blue
  background-image: -moz-linear-gradient(90deg, #106CEB 0%, #5157B5 100%)
  background-image: -webkit-linear-gradient(90deg, #106CEB 0%, #5157B5 100%)
  background-image: linear-gradient(90deg, #106CEB 0%, #5157B5 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
.purple
  background: -moz-linear-gradient(90deg, #5954AE 0%, #A93B68 100%)
  background: -webkit-linear-gradient(90deg, #5954AE 0%, #A93B68 100%)
  background: linear-gradient(90deg, #5954AE 0%, #A93B68 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
.red
  background: -moz-linear-gradient(90deg, #B4375F 0%, #F1222A 100%)
  background: -webkit-linear-gradient(90deg, #B4375F 0%, #F1222A 100%)
  background: linear-gradient(90deg, #B4375F 0%, #F1222A 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
.blue, .purple, .red
  transition: all .5s
  background-size: 100% auto
.blue:hover, .purple:hover, .red:hover
  background-size: 200% auto
  background-position: left center
  filter: brightness(130%)
.social-wrapper
  display: flex
  flex-direction: row
  @media screen and (min-width: 600px)
    justify-content: center
  @media screen and (max-width: 599px)
    flex-direction: column
    width: 100%
