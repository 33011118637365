
.blogs
  @media screen and (max-width: 1023px)
    grid-template-columns: repeat(1,1fr)
  @media screen and (min-width: 1024px)
    grid-template-columns: repeat(4,1fr)
.newest-products, .featured-products
  @media screen and (max-width: 1023px)
    grid-template-columns: repeat(2,1fr)
  @media screen and (min-width: 1024px)
    grid-template-columns: repeat(4,1fr)
.product-grid
  display: grid
  @media screen and (max-width: 1023px)
    grid-gap: 0.5rem
  @media screen and (min-width: 1024px)
    grid-gap: 1rem
.featuresWrapper
  background: rgb(126,121,110)
  background: linear-gradient(30deg, rgba(126,121,110,1) 0%, rgba(32,34,37,1) 100%)
.featuresBg
  background: rgb(232,241,253)
  background: linear-gradient(90deg, rgba(232,241,253,1) 0%, rgba(254,235,235,1) 100%)
.featuresBorderBottom
  height: 2px
  background: rgb(130,178,244)
  background: linear-gradient(90deg, rgba(130,178,244,1) 0%, rgba(248,151,155,1) 100%)
.row > label
  border: 0 solid black !important
.sliderBtn
  width: 35px
  border-radius: 5px
  height: 70px
  background: #DEDEE0
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  &:hover
    background: darkgray
    transition: background-color 0.33s ease-in-out
.categoryWidth
  width: 20%
  @media screen and (max-width: 1200px)
    width: 25% !important
  @media screen and (max-width: 600px)
    width: 50% !important
.featureTitle
  color: #4c4f53
  line-height: 2
  @media screen and (max-width: 1023px)
    font-size: 16px
  @media screen and (min-width: 1024px)
    font-size: 18px
  font-weight: 600
.featureBody
  @media screen and (max-width: 1023px)
    font-size: 14px
  @media screen and (min-width: 1024px)
    font-size: 15px
  color: #4E4F54
  font-weight: 400
.promotionTitle
  color: #4d4f53
  font-weight: 600
  @media screen and (min-width: 601px)
    font-size: 30px
  @media screen and (max-width: 600px)
    font-size: 18px
.categoriesContainer
  color: #242424
  font-size: 19px
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  position: relative
  //margin-top: 35px
  margin-top: 20px
  @media screen and (max-width: 1023px)
    margin-top: 15px
    height: 60px !important
.secondary-carousel-left
  left: 15px
.secondary-carousel-right
  right: 15px
.secondary-carousel-left, .secondary-carousel-right
  z-index: 1
  position: absolute
  top: 40%
  width: 35px
  border-radius: 5px
  height: 70px
  background: darkgray
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  transition: background-color 0.33s ease-in-out
  &:hover
    background: #4D4F53
.featureImg
  width: 50px !important
  height: 50px !important
  @media screen and (max-width: 1023px)
    width: 45px !important
    height: 45px !important
.carouselContainer
  min-height: 30vw
  @media screen and (max-width: 1023px)
    min-height: 133vw
.secondary-wrapper
  @media screen and (max-width: 1023px)
    padding: 0 !important
    margin: 0 !important
.mainTitleContainer
  padding: 20px
  @media screen and (max-width: 1023px)
    padding: 15px !important
.svgSize
  width: 30px
  height: 30px
  @media screen and (max-width: 1023px)
    width: 30px !important
    height: 30px !important
.showMoreBtn
  cursor: pointer
  width: 100%
  text-align: center
  color: #4D4F53
  font-weight: 600
  margin: 12px 0
  border-radius: 10px
  font-size: 20px
  padding: 14px 24px 14px 24px
  border: 2px solid #4D4F53
  @media screen and (max-width: 1023px)
    border-radius: 5px !important
    font-size: 16px !important
    padding: 12px !important
.show-more-link
  @media screen and (max-width: 1023px)
    width: 100%
.secondaryCarouselContainer
  width: 100%
  position: relative
  @media screen and (max-width: 1023px)
    padding: 0 !important
.categoryScrollArea
  height: 59px
  margin: 0
  @media screen and (max-width: 1023px)
    margin: 15px 0 0 0
.featureWrapper
  display: flex
  flex-direction: row
  justify-content: space-between
  height: 160px
  padding: 0 10px
  @media screen and (max-width: 1023px)
    background: none !important
    height: auto !important
    border-radius: 0 !important
    padding: 0 !important
  @media screen and (min-width: 1440px)
    height: 100%
.featureWrapperContainer
  @media screen and (max-width: 1023px)
    padding: 8px 8px !important
    margin-top: 8px !important
  @media screen and (min-width: 1440px)
    height: 100%
    &:nth-of-type(1)
      .featureWrapper
        padding-left:0
    &:nth-of-type(4)
      .featureWrapper
        padding-right:0
.socialLineContainer
  @media screen and (max-width: 1023px)
    padding: 0 !important
    margin: 0 !important
    width: 100% !important
#first-gradient
  --color-stop-1: #405AD9
  --color-stop-2: #4855C6
#second-gradient
  --color-stop-1: #584EAD
  --color-stop-2: #6B4997
#third-gradient
  --color-stop-1: #86457C
  --color-stop-2: #9D4265
#fourth-gradient
  --color-stop-1: #B7414E
  --color-stop-2: #CA423E
.first-gradient
  fill: url(#first-gradient) rgb(3, 112, 247)
.second-gradient
  fill: url(#second-gradient) rgb(3, 112, 247)
.third-gradient
  fill: url(#third-gradient) rgb(3, 112, 247)
.fourth-gradient
  fill: url(#fourth-gradient) rgb(3, 112, 247)
.yt-btn
  border-radius: 8px
  background: #DF2938
.pure-gold-tv
  color: #fff
  font-weight: 600
  padding-left: 6px
  font-size: 22px
.youtubeVideosWrapper
  @media screen and (min-width: 1440px)
    padding-left: 50px
.feature-container
  display: grid
  @media screen and (max-width: 599px)
    grid-template-columns: repeat(1,1fr) !important
  grid-template-columns: repeat(2,1fr)
  grid-gap: 1rem
  grid-auto-flow: dense
  flex-direction: row
  color: white
  align-items: center
  @media screen and (min-width: 1024px) and (max-width: 1440px)
    padding-top: 16px
  @media screen and (min-width: 1440px)
    padding-left: 16px
