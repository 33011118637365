
.categoryContainer
  cursor: pointer
  background: #ECECEE
  width: 100%
  font-weight: 600
  font-size: 18px
  text-transform: uppercase
  color: #646464
  border-color: white
  transition: all .33s
  padding: 10px 16px
  border-radius: 10px
  margin: 0 4px 0 4px
  @media screen and (max-width: 1023px)
    justify-content: center !important
    display: flex !important
    padding: 10px 30px
    border-radius: 40px !important
    flex-direction: row !important
    text-align: end !important
    line-break: strict !important
  .category-name
    @media screen and (min-width: 1024px)
      padding-right: 10px
    text-align: end
  display: flex
  flex-direction: row
  justify-content: space-between
@media screen and (min-width: 1024px)
  .categoryContainer:hover
    color: #FFFFFF
    background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
