
.blogCard
  transition: all 0.33s ease-in-out
  box-shadow: 0 1px 1px 0 rgba(36,36,36,0.2)
  -webkit-box-shadow: 0 1px 1px 0 rgba(36,36,36,0.2)
  -moz-box-shadow: 0 1px 1px 0 rgba(36,36,36,0.2)
  border-radius: 12px
  overflow: hidden
  border: 1px solid #ececee
  text-align: left !important
  height: 100%
  &:hover
   transform: translateY(-3px)
   background-color: white
   border-color: #dbdcdd
  .teaser
    max-height: 200px
    overflow: hidden
  .read-more-button
    background: #e5f0fe
    color: #0370f7
    padding: 12px 20px
    border-radius: 8px
    font-size: 14px
    font-weight:500
    transition-duration: 300ms
    &:hover
      background:#0370f7
      color:#fff
